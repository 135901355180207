.container {
  @apply inline-block relative;
}

.input {
  @apply bg-white px-5 py-2 pr-10 rounded-full text-sm border w-72 focus:outline-none;
}

.icon {
  margin-top: 0.45rem;
  @apply absolute right-0 top-0 mr-4 text-gray-500;
}
