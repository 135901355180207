.pageMenu {
  @apply flex;
}

.pageMenu > * {
  @apply mr-4;
}

.pageMenu > *:last-child {
  @apply mr-0;
}

.tableContainer {
  @apply mt-6;
}

.textLink {
  text-decoration: underline dotted;
}
