.modal {
  width: 500px;
  height: 280px;
  @apply bg-white shadow-lg border m-auto absolute top-16 right-0 left-0 rounded;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.3);
  @apply fixed top-0 right-0 bottom-0 left-0 z-50;
}
