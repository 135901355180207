.pageMenu {
  @apply flex-col;
}

.pageMenu > * {
  @apply mr-4;
}

.pageMenu > *:last-child {
  @apply mr-0;
}
