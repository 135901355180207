.form {
  @apply flex flex-col h-full;
}

.formHeader {
  @apply px-8 py-6 border-b text-xl;
}

.formBody {
  @apply flex-1 px-8 py-6 overflow-scroll;
}

.formFooter {
  @apply bg-gray-50 px-8 py-6;
}

.formFooter > * {
  @apply mr-4;
}

.formGroup {
  @apply mt-2 flex -mx-2;
}

.inputContainer {
  @apply w-full mx-2;
}

.input {
  @apply form-input mt-2 block w-full text-sm rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:ring-0;
}

.inputLabel {
  @apply text-sm font-bold text-gray-600;
}

.inputError {
  @apply text-red-600 my-2 text-sm font-bold;
}
