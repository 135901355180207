.container {
  @apply flex flex-col;
}

.container > .wrapper1 {
  @apply -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8;
}

.container > .wrapper1 > .wrapper2 {
  @apply py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8;
}

.container > .wrapper1 > .wrapper2 > .wrapper3 {
  @apply overflow-hidden border-b border-gray-200 sm:rounded-lg;
}

.container > .wrapper1 > .wrapper2 > .wrapper3 > table {
  @apply min-w-full divide-y divide-gray-200;
}
