.header {
  @apply bg-white fixed flex items-center justify-between w-full shadow h-12 p-8 z-30;
  font-family: 'Kanit', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
}

.logo {
  @apply text-xl font-bold whitespace-nowrap;
}

.logo > img {
  display: inline-block;
  height: 24px;
  @apply mr-1;
}

.menu {
  @apply flex flex-1 mx-8;
}

.menu a {
  @apply block px-4;
}

.logout {
  @apply ml-auto;
}

.main {
  @apply pt-16;
}

.container {
  @apply px-8 py-6;
}
