.button {
  height: 33px;
  @apply inline-flex justify-center items-center px-4 border text-gray-600 font-medium rounded-md focus:outline-none;
}

.menuItems {
  @apply absolute w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none;
}

.menuItem {
  @apply group flex rounded-md items-center w-full px-2 py-2 text-sm;
}
