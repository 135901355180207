.container {
  @apply flex self-center;
}

.page {
  @apply mx-1 w-6 h-6 text-center cursor-pointer;
}

.active {
  @apply font-bold;
}

.previous {
  @apply mx-1 w-6 h-6 text-center font-bold;
}

.next {
  @apply mx-1 w-6 h-6 text-center font-bold;
}
